import dayjs from 'dayjs'
import { Binnacle } from 'modules/contractedService/domain/management/tracking/entities/PetitionTrackingBinnacle'
import { ColData } from 'ufinet-web-components'
import { noContentPlaceholder, TranslatorFunction } from 'ufinet-web-functions'

export const binnacleTableCols = (translator: TranslatorFunction): ColData<Binnacle>[] => [
	{
		field: 'eventType',
		header: 'BINNACLE.EVENT_TYPE',
		width: '20%',
		nonFilterable: true,
		nonSortable: false,
		nonSearchable: false,
		body: (row: Binnacle) =>
			row?.eventType ? translator(`BINNACLE.EVENT_TYPE.${row.eventType}`) : noContentPlaceholder,
	},
	{
		field: 'date',
		header: 'BINNACLE.EVENT_DATE',
		width: '20%',
		nonFilterable: true,
		nonSortable: false,
		nonSearchable: false,
		body: (row: Binnacle) => dayjs(row.date).format('DD/MM/YYYY') || noContentPlaceholder,
	},
	{
		field: 'createdBy',
		header: 'BINNACLE.EVENT_USER',
		width: '20%',
		nonFilterable: true,
		nonSortable: false,
		nonSearchable: false,
	},
	{
		field: 'observation',
		header: 'BINNACLE.EVENT_OBSERVATION',
		width: '40%',
		nonFilterable: true,
		nonSortable: false,
		nonSearchable: false,
	},
]
